<template>
  <div id="app">
    <!-- 路由出口 -->
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'APP',
  methods: {
    // 检查环境
    checkDevice () {
      const href = location.href
      const cid = /cid=(\d+)[&/]?/.exec(href)
      if (cid) { // 邀请码
        this.$store.commit('setInvitationCode', cid[1])
        this.$store.commit('setCid', cid[1])
      }
    }
  },
  created () {
    this.checkDevice()
    if (this.$route.query?.cid) {
      // 获取来源渠道
      this.$store.commit('setCid', this.$route.query.cid)
    }
  }
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100%;
}
body,
html {
  width: 100%;
  height: 100%;
}
</style>
