import _axios from 'axios'
// import NProgress from 'nprogress'
// import 'nprogress/nprogress.css'
import { Message } from 'element-ui'
import store from '@/store/index'
import utils from '@/utils/utils'
import router from '@/router/index'
// 错误码集
import errorCode from './errorCode'
const _request = _axios.create({
  baseURL: process.env.VUE_APP_URL // 环境变量
  // baseURL: 'https://testcn.chatgpt-ai.pw/api' // 环境变量
})
// 请求拦截
_request.interceptors.request.use(
  config => {
    config.headers.Authorization = `Bearer ${store.state.token || (router?.query?.token ?? '')}`
    // console.log(store.state.token)
    if (config.url !== '/chat/image/ernie-result' && config.url !== '/chat/order/check-is-paid' && config.url !== '/chat/image/draft/get-image') {
      // 进度条开始
      // NProgress.start()
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)
// 响应拦截
_request.interceptors.response.use(
  res => {
    if (res.data.code !== 200) {
      Message({
        message: res.data.msg,
        type: 'error'
      })
    }
    // 进度条结束
    // NProgress.done()
    return res.data
  },
  error => {
    console.log(error)
    // token 失效
    if (error?.response?.data?.code === 401) {
      Message({
        message: '登录过期，请重新登录',
        type: 'error'
      })
      store.dispatch('logOut')
      if (!utils.isMobile()) {
        store.commit('header/setIsLogin', true)
        store.commit('login/setModule', 'sign')
      } else {
        router.push('/mobile/login')
      }
      return Promise.reject(error)
    }
    // 多设备登录
    if (error?.response?.data?.code === 1002) {
      store.dispatch('logOut')
      Message({
        message: '帐号在别的设备上登录了，请重新登录.',
        type: 'warning'
      })
      if (utils.isMobile()) {
        router.push('/mobile/login')
      } else {
        store.commit('header/setIsLogin', true)
        store.commit('login/setModule', 'sign')
      }
      return Promise.reject(error)
    }
    if (error?.response?.data?.code !== 200) {
      try {
        const data = errorCode.find((item) => error.response.data.code === Number(item.code))
        Message({
          message: data.message,
          type: 'error'
        })
      } catch (err) {

      }
    }
    // 进度条结束
    // NProgress.done()
    return Promise.reject(error)
  }
)
export default _request
